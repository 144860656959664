export default (lang, minimumFractionDigits = 2, maximumFractionDigits = 2) => {
  if (!lang) {
    throw new Error('number-format locale not set');
  }

  if (lang.length !== 2) {
    throw new Error(`number-format locale must be 2 chars long but is: ${lang}`);
  }

  const nfLocale = `${lang}-${lang.toUpperCase()}`;

  return new Intl.NumberFormat(nfLocale, { minimumFractionDigits, maximumFractionDigits });
};
