import React from 'react';
import PropTypes from 'prop-types';

import numberFormatter from '../../../lib/number-formatter';
import t from '../../../lib/translate';

import * as styles from './component.module.scss';

// eslint-disable-next-line camelcase
export const getProductPrice = ({
  price_range: {
    minimum_price: {
      final_price: finalPrice,
      regular_price: regularPrice,
    },
  },
}) => {
  if (finalPrice.value && finalPrice.value < regularPrice.value) {
    return {
      finalPrice: finalPrice.value,
      regularPrice: regularPrice.value,
      currency: finalPrice.currency,
    };
  }

  return {
    finalPrice: regularPrice.value,
    regularPrice: regularPrice.value,
    currency: regularPrice.currency,
  };
};

const taxText = (price) => {
  if (price >= 500) {
    return t`product_price_incl_tax_incl_shipping`;
  }

  if (price >= 50) {
    return t`product_price_incl_tax_incl_shipping_de`;
  }

  return t`product_price_incl_tax_excl_shipping`;
};

const ProductPrice = ({
  lang,
  product,
  classNamePrefix,
  showTax,
}) => {
  const { regularPrice, finalPrice } = getProductPrice(product);

  return (
    <>
      {regularPrice > finalPrice && (
        <strike className={styles.pStrike}>
          {`${numberFormatter(lang).format(regularPrice)}€`}
        </strike>
      )}
      <span className={`${classNamePrefix}price`}>
        {`${numberFormatter(lang).format(finalPrice)}€`}
      </span>
      {showTax && (
        <span className={`${classNamePrefix}tax`}>
          {taxText(finalPrice)}
        </span>
      )}
    </>
  );
};

ProductPrice.defaultProps = {
  classNamePrefix: '',
  showTax: false,
};

ProductPrice.propTypes = {
  lang: PropTypes.string.isRequired,
  classNamePrefix: PropTypes.string,
  showTax: PropTypes.bool,
  product: PropTypes.shape({
    sku: PropTypes.string,
    price_range: PropTypes.shape({
      minimum_price: PropTypes.shape({
        regular_price: PropTypes.shape({
          currency: PropTypes.string,
          value: PropTypes.number,
        }),
        final_price: PropTypes.shape({
          currency: PropTypes.string,
          value: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
};

export default ProductPrice;
