import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate/index';

import Layout from '../layouts/default';

import Helmet from '../components/helmet';

import Header from '../component-elements/header';

import CmsImageComponent from '../content-elements/image';
import CmsBrandContactComponent from '../content-elements/brandContact';
import CmsShopCategoryComponent from '../content-elements/shopCategory';
import CmsTextTeaserComponent from '../content-elements/textTeaser';
import CmsBrandsSliderComponent from '../content-elements/brandSlider';
import CmsTextSimpleComponent from '../content-elements/textSimple';
import CmsNewsTeaserComponent from '../content-elements/newsTeaser';
import FlexibleTeaserComponent from '../content-elements/teaser';
import ImageTextElement from '../content-elements/imageTextElement';
import CmsResponsiveTableComponent from '../content-elements/responsiveTable';
import CmsVtour from '../content-elements/CmsVtour';
import CmsImageGallery from '../content-elements/GalleryImages';
import Breadcrumb from '../component-elements/breadcrumb';
import MapTextElement from '../content-elements/mapTextElement';

const components = {
  DatoCmsTextSimple: CmsTextSimpleComponent,
  DatoCmsImage: CmsImageComponent,
  DatoCmsBrandContact: CmsBrandContactComponent,
  DatoCmsShopCategoryTeaser: CmsShopCategoryComponent,
  DatoCmsImageTextTeaser: CmsTextTeaserComponent,
  DatoCmsBrandSlider: CmsBrandsSliderComponent,
  DatoCmsNewsTeaser: CmsNewsTeaserComponent,
  DatoCmsImageTextElement: ImageTextElement,
  DatoCmsImageGallery: CmsImageGallery,
  DatoCmsResponsiveTable: CmsResponsiveTableComponent,
  DatoCmsVtour: CmsVtour,
  DatoCmsTeaserFlexible: FlexibleTeaserComponent,
  DatoCmsMapText: MapTextElement,
};

const CmsTemplate = ({
  data: {
    datoCmsPage: {
      seoMetaTags: helmetProps,
      header: headerElements = [],
      content: cmsContent = [],
      metaRobots,
      metaCanonical,
      textcolorHeader,
      textcolorHeaderMobile,
      ...page
    },
  },
        pageContext,
}) => (
  <Layout textcolorHeader={textcolorHeader} textcolorHeaderMobile={textcolorHeaderMobile}>
    {page.url === 'index' ? (
      <h1 className="d-none">{t`h1_homepage`}</h1>
    ) : (
      <Breadcrumb
        items={[
          { id: page.name, name: page.name },
        ]}
      />
    )}

    {headerElements.length > 0 && (
      <Header data={headerElements[0]} />
    )}

    {cmsContent.map((singleComponent, index) => {
      if (typeof singleComponent === 'object' && Object.keys(singleComponent).length > 0) {
        const Component = components[singleComponent.__typename];
        return (
          <Component
            key={singleComponent.id}
            {...singleComponent}
            swiperId={singleComponent.id}
          />
        );
      }
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`unknown-${index}`}><h4>This content element can not be rendered.</h4></div>
      );
    })}

    <Helmet
      tags={helmetProps}
      metaRobots={metaRobots}
      metaCanonical={metaCanonical}
      title={page.seo?.title}
      titleFallback={t('cms_page_title_fallback', page.name)}
      imageFallback={
        headerElements.length > 0 && headerElements[0].image
          ? { alt: headerElements[0].image.alt, src: headerElements[0].image.url }
          : undefined
      }
      url={pageContext.url}
    />
  </Layout>
);

CmsTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  pageContext: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default CmsTemplate;

export const query = graphql`
    query cmsQuery($url: String, $lang: String) {
        datoCmsPage(url: {eq: $url}, locale: {eq: $lang}) {
            ...SeoMetaTags
            ...header
            ...content
            metaCanonical
            metaRobots
            textcolorHeader
            textcolorHeaderMobile
        }
    }
`;
