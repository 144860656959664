import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';

import t from '../../../lib/translate';

import { useAccountId } from '../../../lib/service/account/useAccountId';

import { productGridBtnWishlist } from '../item/component.module.scss';

const AddToWishlistLoggedIn = lazy(() => import('./LoggedIn'));
const AddToWishlistLoggedOut = lazy(() => import('./LoggedOut'));

const renderDefault = (iconOnly, label) => (
  <button
    disabled
    type="button"
    className={`btn btn-link icon-gold icon-wishlist product-button-wishlist btn-link-disabled icon icon-wishlist${iconOnly ? ` ${productGridBtnWishlist}` : ''}`}
  >
    {iconOnly && (
      <span className="sr-only">{label}</span>
    )}
    {!iconOnly && label}
  </button>
);

const AddToWishlist = ({ sku, iconOnly }) => {
  const account = useAccountId();

  const label = t('product_action_wishlist_add_label');

  if (account.isLoading) {
    return renderDefault(iconOnly, label);
  }

  return (
    <Suspense fallback={renderDefault(iconOnly, label)}>
      <>
        {account.data && (
          <AddToWishlistLoggedIn
            iconOnly={iconOnly}
            sku={sku}
            label={label}
          />
        )}
        {!account.data && (
          <AddToWishlistLoggedOut
            iconOnly={iconOnly}
            sku={sku}
            label={label}
          />
        )}
      </>
    </Suspense>
  );
};

AddToWishlist.defaultProps = {
  iconOnly: false,
};

AddToWishlist.propTypes = {
  sku: PropTypes.string.isRequired,
  iconOnly: PropTypes.bool,
};

export default AddToWishlist;
