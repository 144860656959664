import React from 'react';
import PropTypes from 'prop-types';

import { productGridItem } from '../../component.module.scss';
import * as styles from './component.module.scss';

const Placeholder = () => (
  <article className={`${productGridItem} ${styles.placeholder}`}>
    <span className={styles.img} />
    <span className={`${styles.txt} ${styles.title}`}>&nbsp;</span>
    <span className={`${styles.txt} ${styles.headline}`}>&nbsp;</span>
    <span className={`${styles.txt} ${styles.headline}`}>&nbsp;</span>
    <span className={`${styles.txt} ${styles.subline}`}>&nbsp;</span>
    <span className={`${styles.txt} ${styles.subline}`}>&nbsp;</span>
    <span className={`${styles.txt} ${styles.extra}`}>&nbsp;</span>
  </article>
);

// eslint-disable-next-line react/prop-types
const SinglePlaceholder = ({
  className,
  center,
  width,
  children,
}) => (
  <span className={`${styles.placeholder}${!center ? ` ${styles.noCtr}` : ''}`}>
    <span
      className={`${styles.txt}${className ? ` ${className}` : ''}`}
      style={{
        width,
      }}
    >
      {!children}
      <>&nbsp;</>
    </span>
  </span>
);

SinglePlaceholder.propTypes = {
  width: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

SinglePlaceholder.defaultProps = {
  width: undefined,
  className: undefined,
  center: true,
  children: [],
};

export {
  Placeholder,
  SinglePlaceholder,
};
