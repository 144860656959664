// see graphql fragment "shopProductListItem"
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'gatsby';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';

import {propTypes, defaultProps} from './propTypes';

import findBrand from '../../../lib/find-brand';
import t from '../../../lib/translate';

import ProductImage from '../image';
import Price from '../price';
import AddToWishlist from '../add-to-wishlist';
import ProductFlags from '../flags';
import {Placeholder, SinglePlaceholder} from './components/placeholder';

import * as styles from './component.module.scss';

const Product = ({
					 isPlaceholder,
					 isPricePlaceholder,
					 name,
					 sku,
					 lang,
					 jr_manufacturer_number: jrManufacturerNumber,
					 jr_brand: jrBrand,
					 urlPrefix,
					 url_key: urlKey,
					 small_image: smallImage,
					 dwFlags,
					 price_range: priceRange,
					 breadcrumb,
					 withAddToWishlist,
					 imageSize,
					 imageLoading
				 }) => {
	if(isPlaceholder) {
		return (
				<Placeholder />
		);
	}

	const brand = findBrand(jrBrand);

	return (
			<article className={styles.productGridItem}>
				<div className={styles.productGridItemDetails}>
					<h3 className={styles.productGridItemName}>
						{name.trim()}
					</h3>

					{brand && (
							<span className={styles.productGridItemBrand}>{brand.name}</span>
					)}

					<div className={styles.productGridItemSkus}>
						<div>
							{jrManufacturerNumber && (
									<div>
                <span className="mr-2">
                  {t`product_manufacturer_number_label`}
                </span>
										{jrManufacturerNumber}
									</div>
							)}

							<>
              <span className="mr-2">
                {t`product_sku_label`}
              </span>
								{sku}
							</>
						</div>
					</div>

					{!isPricePlaceholder && priceRange && (
							<div className={styles.productGridItemPrice}>
								<Price
										lang={lang}
										product={{
											sku,
											price_range: priceRange
										}}
								/>
							</div>
					)}

					{isPricePlaceholder && (
							<div className={styles.productGridItemPrice}>
								<SinglePlaceholder className="price">????,?? &euro;</SinglePlaceholder>
							</div>
					)}

					{withAddToWishlist && (
							<AddToWishlist sku={sku} iconOnly />
					)}
				</div>

				{urlKey && (
						<Link
								to={`${urlPrefix ? `/${urlPrefix}` : ''}/${urlKey}/`}
								className={styles.productGridItemLink}
								state={{
									breadcrumb
								}}
						>
							<span className="sr-only">{name}</span>
						</Link>
				)}

				<ProductFlags {...dwFlags} />

				<figure className={styles.productGridItemFigure}>
					{smallImage && (
							<ProductImage image={smallImage.url} width={imageSize} height={imageSize}
										  alt={smallImage.label} loading={imageLoading} />
					)}

					{brand?.logoBlack?.gatsbyImageData && (
							<div className={styles.productGridItemBrandImage}>
								<GatsbyImage
										image={getImage(brand.logoBlack.gatsbyImageData)}
										alt={brand.logoBlack.alt}
										title={brand.logoBlack.title}
								/>
							</div>
					)}
				</figure>
			</article>
	);
};

Product.propTypes = {
	lang: PropTypes.string.isRequired,
	...propTypes
};
Product.defaultProps = defaultProps;

export default Product;
