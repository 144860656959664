import React from 'react';
import PropTypes from 'prop-types';

import t from '../../../lib/translate';

import * as styles from './component.module.scss';

const ProductFlags = ({
  new: isNew = false,
  secondhand = false,
  limited = false,
  discount = 0,
}) => (
  <>
    {isNew && (
      <span className={styles.flag}>
        {t`product_flag_new_label`}
      </span>
    )}
    {secondhand && (
      <span className={styles.flag}>
        {t`product_flag_secondhand_label`}
      </span>
    )}
    {limited && (
      <span className={styles.flag}>
        {t`product_flag_limited_label`}
      </span>
    )}
    {discount > 0 && (
      <span className={styles.flag}>
        {t('product_flag_discount_label', discount)}
      </span>
    )}
  </>
);

ProductFlags.propTypes = {
  new: PropTypes.bool,
  secondhand: PropTypes.bool,
  limited: PropTypes.bool,
  discount: PropTypes.number,
};

ProductFlags.defaultProps = {
  new: false,
  secondhand: false,
  limited: false,
  discount: 0,
};

export default ProductFlags;
