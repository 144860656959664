/* eslint-disable */
import source from '../../../brands';

const findBrand = (id) => {
  if (!id) {
    return undefined;
  }
  return source[id.toString()];
};

export default findBrand;
