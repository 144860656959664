// extracted by mini-css-extract-plugin
export var columns3 = "component-module--columns-3--29275";
export var hasItems1 = "component-module--has-items-1--0a180";
export var hasItems2 = "component-module--has-items-2--e2c52";
export var hasItems3 = "component-module--has-items-3--9b166";
export var productGrid = "component-module--product-grid--ebbf8";
export var productGridArticles = "component-module--product-grid-articles--d8f1d";
export var productGridBtnCart = "component-module--product-grid-btn-cart--2d0d0";
export var productGridBtnWishlist = "component-module--product-grid-btn-wishlist--8ab13";
export var productGridItem = "component-module--product-grid-item--7a77f";
export var productGridItemBrand = "component-module--product-grid-item-brand--c23c6";
export var productGridItemBrandImage = "component-module--product-grid-item-brand-image--a9ab5";
export var productGridItemDetails = "component-module--product-grid-item-details--61727";
export var productGridItemFigure = "component-module--product-grid-item-figure--989ee";
export var productGridItemImage = "component-module--product-grid-item-image--951eb";
export var productGridItemLink = "component-module--product-grid-item-link--d0c5b";
export var productGridItemName = "component-module--product-grid-item-name--b1f53";
export var productGridItemPrice = "component-module--product-grid-item-price--23f8a";
export var productGridItemSkus = "component-module--product-grid-item-skus--7efb9";