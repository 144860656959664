import PropTypes from 'prop-types';

const propTypes = {
  sku: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.bool,
  isPricePlaceholder: PropTypes.bool,
  withAddToWishlist: PropTypes.bool,
  name: PropTypes.string,
  url_key: PropTypes.string,
  jrManufacturerNumber: PropTypes.string,
  breadcrumb: PropTypes.arrayOf(PropTypes.any),
  urlPrefix: PropTypes.string,
  price_range: PropTypes.objectOf(PropTypes.any),
  imageSize: PropTypes.number,
  imageLoading: PropTypes.string,
  jr_brand: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  small_image: PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
  dwFlags: PropTypes.shape({
    new: PropTypes.bool,
    secondhand: PropTypes.bool,
    limited: PropTypes.bool,
    discount: PropTypes.number,
  }),
};

const defaultProps = {
  isPlaceholder: false,
  isPricePlaceholder: false,
  withAddToWishlist: true,
  name: '',
  url_key: undefined,
  price_range: undefined,
  jr_brand: null,
  breadcrumb: [],
  urlPrefix: null,
  jrManufacturerNumber: null,
  dwFlags: {},
  small_image: undefined,
  imageSize: 688,
  imageLoading: undefined,
};

export {
  propTypes,
  defaultProps,
};
